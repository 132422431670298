<script>
	import LoginProvider from './LoginProvider.svelte';
	import { locale, _ } from 'svelte-i18n';
	import logins from '../../../../svr/providers/logins.json';
	import { data, showSpinner, isRemoteAuthClient } from '../stores.js';
	import { postLoginProvider, postLinkProvider } from '../utils/api-calls.js';
	import { createEventDispatcher } from 'svelte';
	import { logPlausibleEvent } from '../utils/helper';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import ManagedProviderDropdown from './ManagedProviderDropdown.svelte';
	import QRCodeIcon from './icon/QRCodeIcon.svelte';

	const dispatch = createEventDispatcher();

	export let prefix = '';
	export let login = false;
	export let cryptoWallets = true;
	export let showInstagram = false;
	export let showManagedLogin = true;
	export let ignoreProviders = [];
	export let continueWithQRCodeAjax = false;
	export let expandManagedProvider = false;
	export let accountSelected = undefined;
	export let showQRLogin = false;

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			if (login) {
				const res = await postLoginProvider({
					slug,
					server,
					access: window.isWalletMastodonApp && ['mastodon', 'twitter', 'github'].includes(slug),
					body: { accountSelected }
				});
				//error if !res.redirect is thrown at postLoginProvider
				console.log({ redirect: res.redirect });

				//New User Release Funnel
				if (window.isWalletAuthorizeApp) {
					const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
						sessionStorage.az_release_funnel
					);
					const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
					//session funnel state is valid and not already sent + is authorize app
					if (
						indexOfCurrentFunnelStep !== -1 &&
						indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
						window.isWalletAuthorizeApp
					) {
						const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
							'client_id'
						);
						const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
							'redirect_uri'
						);
						let redirect;
						try {
							redirect = new URL(redirect_uri)?.hostname;
						} catch (err) {
							console.error(err);
						}
						//recommended_provider is always false in this case since this component only renders for below fold (non-recommended) providers
						await logPlausibleEvent({
							n: 'AZ Login Start',
							p: { client_id, provider: slug, recommended_provider: false, redirect },
							u: '/login'
						});
						sessionStorage.setItem('az_release_funnel', 'az_login_start');
					}
				}
				window.location.href = res.redirect;
			} else {
				const res = await postLinkProvider({
					slug,
					server,
					access: window.isWalletMastodonApp && ['mastodon', 'twitter', 'github'].includes(slug)
				});
				//error if !res.redirect is thrown at postLinkProvider
				console.log({ redirect: res.redirect });

				//Wizard Funnel
				const isInWizard = !$data?.profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
				if (window.isWalletApp && isInWizard) {
					const preferred = $data?.preferred?.[0]?.slug;
					let welcome_email_app;
					if (sessionStorage.welcome_app_info) {
						try {
							welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
						} catch (err) {
							console.error(err);
						}
					}
					const recovery_1 = $data?.recovery?.[0]?.slug;
					if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
						await logPlausibleEvent({
							n: 'Wiz Recovery 1 Start',
							p: { preferred, welcome_email_app, recovery_1: slug },
							u: '/wizard/recoveryprovider'
						});
						sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
					} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
						await logPlausibleEvent({
							n: 'Wiz Recovery 2 Start',
							p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
							u: '/wizard/recoveryprovider'
						});
						sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
					}
				}

				window.location.href = res.redirect;
			}
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}
</script>

<div class="space-y-2">
	{#if window.isWalletMastodonApp && !ignoreProviders.includes('mastodon')}
		<LoginProvider
			{login}
			on:click={(e) => continueWithProvider('mastodon', e.detail)}
			provider={{ slug: 'mastodon', display: 'Mastodon' }}
			prefix={!login &&
			$data?.profile?.accounts?.filter((i) => i.slug === 'mastodon' && i.recovery && !i.managed)
				.length
				? `${prefix} additional`
				: prefix}
		/>
		<LoginProvider
			{login}
			on:click={(e) => continueWithProvider('twitter', e.detail)}
			provider={{ slug: 'twitter', display: 'X (Twitter)' }}
			prefix={!login &&
			$data?.profile?.accounts?.filter((i) => i.slug === 'twitter' && i.recovery && !i.managed)
				.length
				? `${prefix} additional`
				: prefix}
		/>
	{/if}

	{#if cryptoWallets && !ignoreProviders.includes('ethereum')}
		{#if window.ethereum?.isMetaMask}
			<LoginProvider
				on:click={() => dispatch('ethereum')}
				provider={{ display: 'MetaMask', slug: 'metamask' }}
				prefix={!login &&
				$data?.profile?.accounts?.filter(
					(i) => i.wallet?.name === 'MetaMask' && i.recovery && !i.managed
				).length
					? `${prefix} additional`
					: prefix}
			/>
		{:else if window.ethereum}
			<LoginProvider
				on:click={() => dispatch('ethereum')}
				provider={{ display: 'Ethereum', slug: 'ethereum' }}
				prefix={!login &&
				$data?.profile?.accounts?.filter((i) => i.slug === 'ethereum' && i.recovery && !i.managed)
					.length
					? `${prefix} additional {provider}`
					: `${prefix} {provider}`}
			/>
		{/if}
	{/if}

	{#if showManagedLogin && !ignoreProviders.includes('managed')}
		<ManagedProviderDropdown
			label={$data?.profile?.accounts?.filter(
				(i) => i.slug === 'managed' && i.recovery && !i.managed
			).length
				? $_(prefix + ' additional Managed Provider')
				: $_(prefix + ' Managed Provider')}
			bind:expanded={expandManagedProvider}
			on:managedEmailSuccess={() => dispatch('managedEmailSuccess')}
			{login}
			{accountSelected}
			hasBackground={true}
		/>
	{/if}

	{#each logins.filter((i) => !login || (login && !i.no_login)) as provider}
		{#if !ignoreProviders.includes(provider.slug)}
			<!-- Show Instagram only in /mastodon -->
			{#if provider.slug !== 'instagram' || (provider.slug === 'instagram' && !login && showInstagram) || (provider.slug === 'instagram' && window.isWalletMastodonApp)}
				<!-- Show Mastodon and Twitter at top in /mastodon -->
				{#if provider.slug !== 'mastodon' || (provider.slug === 'mastodon' && !window.isWalletMastodonApp)}
					{#if provider.slug !== 'twitter' || (provider.slug === 'twitter' && !window.isWalletMastodonApp)}
						<LoginProvider
							{login}
							on:click={(e) => continueWithProvider(provider.slug, e.detail)}
							{provider}
							prefix={!login &&
							$data?.profile?.accounts?.filter(
								(i) => i.slug === provider.slug && i.recovery && !i.managed
							).length
								? `${prefix} additional`
								: prefix}
						/>
					{/if}
				{/if}
			{/if}
		{/if}

		<!-- Show email & phone after Google -->
		{#if provider.slug === 'google'}
			<slot />
		{/if}
	{/each}

	<!-- Note: Uncomment to show WalletConnect -->
	<!-- {#if cryptoWallets && !ignoreProviders.includes('ethereum')}
		<button
			class="group h-12 relative btn-background w-full flex items-center justify-start px-4"
			on:click={() => dispatch('walletconnect')}
		>
			<img
				src="https://cdn.hello.coop/images/walletconnect.svg"
				alt="walletconnect"
				class="w-4.5 max-h-[18px]"
			/>

			<span class="{$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'} truncate">
				{#if prefix === 'Add'}
					{$_('Add with {provider}', { values: { provider: 'WalletConnect' } })}
				{:else if prefix === 'Link'}
					{$_('Link with {provider}', { values: { provider: 'WalletConnect' } })}
				{:else}
					{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
				{/if}
			</span>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-white dark:text-[#d4d4d4] opacity-80"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
			</svg>
		</button>
	{/if} -->

	{#if login && showQRLogin && !$isRemoteAuthClient && !ignoreProviders.includes('qrcode')}
		<button
			class="group h-12 relative btn-background w-full flex items-center justify-start px-4"
			on:click={() => dispatch('qrcode')}
			disabled={continueWithQRCodeAjax}
		>
			{#if continueWithQRCodeAjax}
				<SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
			{:else}
				<QRCodeIcon css="h-4.5 opacity-80" />

				<span class="{$locale && $locale.startsWith('ar') ? 'mr-4' : 'ml-4'} truncate">
					{$_('Continue by scanning QR Code')}
				</span>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-white dark:text-[#d4d4d4] opacity-80"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
				</svg>
			{/if}
		</button>
	{/if}
</div>
