<script>
	import { _ } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import { createEventDispatcher, onMount, onDestroy, tick } from 'svelte';
	import { data, notification } from '../stores.js';
	import intlTelInput from 'intl-tel-input';
	import 'intl-tel-input/build/css/intlTelInput.css';
	import {
		postLoginPhone,
		postLinkPhone,
		postLinkPhoneCode,
		postVerifyPhone,
		postVerifyPhoneCode
	} from '../utils/api-calls.js';
	import SvelteOtp from '@hellocoop/svelte-otp';
	import { getDeviceTheme, logPlausibleEvent } from '../utils/helper.js';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import FullPageModal from './modal/FullPageModal.svelte';

	const dispatch = createEventDispatcher();

	// export let noautofocus = false;
	export let phone = '';
	export let disabled = false;
	export let accountSelected = undefined;
	export let useDifferentManaged = undefined;

	export let login = false;
	export let accountToUse = undefined;
	export let verify = false;

	export let recommendedProvider = false;

	let otp = '';
	let buttonDisabled = true;

	let ajaxRequestSend = false;
	let ajaxRequestResend = false;
	let verifyOTPAjax = false;
	let alreadyVerfiedModal = false;

	let phoneOTPState = false;

	let ref, iti;

	let verifiedPhones = [];

	onMount(() => {
		if (!disabled) {
			ref = document.getElementById('tel');
			iti = intlTelInput(ref, {
				autoHideDialCode: false,
				utilsScript: window.intlTelInputUtils,
				separateDialCode: true
			});
			// if (!noautofocus) {
			// 	document.getElementById('tel').focus();
			// }
		}

		if (($data?.isPersonalLoggedIn || $data?.isManagedLoggedIn) && !login && !verify) {
			if (window.isWalletAuthorizeApp && Array.isArray($data.release?.phones)) {
				verifiedPhones = $data.release.phones;
			} else if (
				Array.isArray($data.profile?.accounts) &&
				Array.isArray($data.profile?.unverified_phones)
			) {
				const _verifiedPhones = $data.profile.accounts
					.filter(
						(i) => i.slug === 'phone' && !$data.profile.unverified_phones.includes(i.user_name)
					)
					.map((i) => i.user_name);
				verifiedPhones = _verifiedPhones;
			}
		}
	});

	onDestroy(() => {
		if (iti) {
			iti.destroy();
		}
	});

	async function verifyPhone(phone, resend) {
		try {
			if (login) await postLoginPhone(phone, resend);
			else if (verify) await postVerifyPhone(phone, resend);
			else await postLinkPhone(phone, resend);

			//New User Release Funnel
			if (window.isWalletAuthorizeApp) {
				const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
					sessionStorage.az_release_funnel
				);
				const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
				//session funnel state is valid and not already sent + is authorize app
				if (
					login &&
					indexOfCurrentFunnelStep !== -1 &&
					indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
					window.isWalletAuthorizeApp
				) {
					const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'client_id'
					);
					const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
						'redirect_uri'
					);
					let redirect;
					try {
						redirect = new URL(redirect_uri)?.hostname;
					} catch (err) {
						console.error(err);
					}
					logPlausibleEvent({
						n: 'AZ Login Start',
						p: {
							client_id,
							provider: 'phone',
							recommended_provider: recommendedProvider,
							redirect
						},
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_start');
				}
			}

			//Wizard Funnel
			const isInWizard = !$data?.profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
			//is trying to link + is in wizard + is wallet app
			if (!login && !verify && isInWizard && window.isWalletApp) {
				const preferred = $data?.preferred?.[0]?.slug;
				let welcome_email_app;
				if (sessionStorage.welcome_app_info) {
					try {
						welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
					} catch (err) {
						console.error(err);
					}
				}
				const recovery_1 = $data?.recovery?.[0]?.slug;
				if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 1 Start',
						p: { preferred, welcome_email_app, recovery_1: 'email' },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
				} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
					await logPlausibleEvent({
						n: 'Wiz Recovery 2 Start',
						p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
						u: '/wizard/recoveryprovider'
					});
					sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
				}
			}

			dispatch('otp');
			phoneOTPState = true;
			await tick();
			if (!resend) {
				if (!disabled) {
					iti.destroy();
				}
				phoneOTPState = true;
			}
		} catch (err) {
			if (err.status === 401 && !login) {
				const { error } = await err.json();
				if (error === 'PHONE_ALREADY_VERIFIED') {
					alreadyVerfiedModal = true;
				}
			} else {
				console.error(err);
			}
		} finally {
			ajaxRequestSend = ajaxRequestResend = false;
		}
	}

	async function verifyPhoneCode(code) {
		try {
			verifyOTPAjax = true;
			if (login) {
				const res = await fetch(
					`/api/v1/login/contact/phone/code?prefers-color-scheme=${getDeviceTheme()}&language=${
						window.navigator.language
					}`,
					{
						headers: {
							'Content-Type': 'application/json'
						},
						method: 'POST',
						body: JSON.stringify({ code, accountToUse, accountSelected, useDifferentManaged })
					}
				);

				if (!res.ok) {
					if (res.status === 401) {
						const json = await res.json();

						if (!(json.isPersonalLoggedIn && json.isManagedLoggedIn) && 'preferred' in json) {
							$notification = {
								text: '',
								type: ''
							};
							$data = json;
							dispatch('error', res);
							return;
						}
					}

					if (res.status === 404) {
						const { error } = await res.json();
						if (error.message === 'INVALID_CODE') {
							$notification = {
								text: $_('Incorrect verification code'),
								type: 'error'
							};
						}
					}

					throw res;
				}
				$notification = {
					text: '',
					type: ''
				};
			} else if (verify) {
				await postVerifyPhoneCode(code);
			} else {
				await postLinkPhoneCode(code);
			}
			dispatch('success', { phone: disabled ? phone : iti.getNumber() });
		} catch (err) {
			otp = '';
			verifyOTPAjax = false;
			dispatch('error', err);
		}
	}

	$: if (otp.length === 6) {
		verifyPhoneCode(otp);
	}

	function clear() {
		if (ref) {
			ref.value = '';
		}
	}
</script>

{#if alreadyVerfiedModal}
	<FullPageModal
		dataTest="phone-exists-error-modal"
		on:close={() => {
			phone = '';
			clear();
			alreadyVerfiedModal = false;
			buttonDisabled = true;
		}}
	>
		<h1 class="text-center">
			<span class="font-semibold">{phone}</span><br />
			<span class="mt-1 block">has already been verified</span>
		</h1>
		<button
			data-test="ok-btn"
			on:click={() => {
				phone = '';
				clear();
				alreadyVerfiedModal = false;
				buttonDisabled = true;
			}}
			class="btn-background w-full mt-6">OK</button
		>
	</FullPageModal>
{/if}

{#if !phoneOTPState}
	<div id="phone-wrapper" class="text-center">
		<form
			on:submit|preventDefault={() => {
				ajaxRequestSend = true;
				verifyPhone(disabled ? phone : iti.getNumber());
			}}
		>
			<div>
				{#if !disabled}
					<input
						type="tel"
						name="tel"
						id="tel"
						autocomplete="tel"
						placeholder={$_('enter your phone')}
						class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
						required
						on:input={() => {
							buttonDisabled = !iti.isValidNumber();
							phone = iti.getNumber();
						}}
					/>
				{/if}
				{#if !login && disabled}
					<span class="h-6 flex items-center justify-center">{phone}</span>
				{/if}

				{#if verifiedPhones.includes(phone)}
					<span
						data-test="phone-exists-error"
						class="text-red-500 text-left mt-2 block"
						transition:slide>Phone has already been verified</span
					>
				{/if}

				<button
					data-test="phone-send-verification-btn"
					type="submit"
					disabled={ajaxRequestSend ||
						(buttonDisabled && !disabled) ||
						verifiedPhones.includes(phone)}
					class="disabled:opacity-60 relative transition btn-background h-12 w-full inline-flex items-center justify-center"
					class:mt-3={!disabled || !login}
				>
					{#if ajaxRequestSend}
						<SpinnerIcon css="h-5 w-5 text-white" />
					{:else if login}
						{$_('Send verification code to log in')}
					{:else}
						{$_('Send verification code')}
					{/if}
				</button>
			</div>
		</form>
	</div>
{:else}
	<div class="text-center" in:fly={{ x: 20 }}>
		<div class="flex justify-center items-center">
			{#if disabled && login}
				<h1 class="text-lg text-center">{$_('Enter the 6-digit code you received')}</h1>
			{:else}
				<h1 class="text-lg text-center">
					{$_('Enter 6 digit code sent to {contact}', {
						values: { contact: disabled ? phone : iti.getNumber() }
					})}
				</h1>
			{/if}
		</div>
		<form>
			{#if !verifyOTPAjax}
				<SvelteOtp
					bind:value={otp}
					autofocus={true}
					numOfInputs={6}
					wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
					numberOnly={true}
					inputClass="text-lg !w-9 !h-11 !border-none"
				/>

				<button
					data-test="phone-resend-verification-btn"
					on:click|preventDefault={() => {
						ajaxRequestResend = true;
						verifyPhone(disabled ? phone : iti.getNumber(), true);
					}}
					disabled={ajaxRequestResend}
					class="focus:underline hover:underline relative h-5 text-sm inline-flex items-center opacity-80 justify-center font-medium"
				>
					{#if ajaxRequestResend}
						<SpinnerIcon css="h-4 w-4 block mx-auto" />
					{:else}
						{$_('Resend verification code')}
					{/if}
				</button>
			{:else}
				<div class="mx-auto h-20 flex justify-center items-center">
					<SpinnerIcon css="h-6 w-6 block mx-auto" />
				</div>
				<span class="h-6 block" />
			{/if}
		</form>
	</div>
{/if}
